
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/

@import "shared/functions.scss";
@import "shared/mixins.scss";
@import "shared/vars";
@import "shared/animations";

/*
* COMMON BLOCKS
*/

@import "common/_homepage-events";

/*
* DESIGN COMPONENTS
*/

//@import "blocks/social";
//@import "blocks/tags";
@import "blocks/introduction";
@import "blocks/card";
@import "blocks/board-card";
@import "blocks/abo";
@import "blocks/homepage-tiles";
//@import "blocks/icon-links";
@import "blocks/blender";
@import "blocks/figure";
//@import "blocks/detail";
@import "blocks/tabs";
@import "blocks/banners";

/*
* HIGHEST PRIORITY
*/

@import "common/_home";
