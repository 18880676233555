.card {
	background-color: #ffffff;
	padding: 0 0 1.7rem;
	position: relative;
	transition: background-color .2s ease;
	height: 100%;
	box-shadow: 0 0 0 -25px;
	transition: box-shadow .3s ease-in-out;

	&:hover {
		background-color: #ffffff;
		box-shadow: 0px 35px 25px -25px rgba(#000000, .25);
	}

	&:focus-within {
		background-color: #ffffff;
	}

	&__image {
		position: relative;
		margin: 0 0 0;
		max-width: 100%;
		height: 0;
		padding-bottom: ratio(3,2);
		width: 100%;
		&:empty {
			background: lightgray encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="85px" fill="#fff" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g><path d="M881.1,132.5H118.9C59,132.5,10,181.5,10,241.4v517.3c0,59.9,49,108.9,108.9,108.9h762.2c59.9,0,108.9-49,108.9-108.9V241.4C990,181.5,941,132.5,881.1,132.5z M949.2,747.3c0,54.9-24.5,79.4-79.4,79.4H130.3c-54.9,0-79.4-24.5-79.4-79.4V252.7c0-54.9,24.5-79.4,79.4-79.4h739.5c54.9,0,79.4,24.5,79.4,79.4V747.3z M316.3,418.3L418.3,500l265.4-224.6l204.2,183.8v306.3H112.1V581.7L316.3,418.3z M193.8,234.6c-45.1,0-81.7,36.6-81.7,81.7s36.6,81.7,81.7,81.7s81.7-36.6,81.7-81.7S238.9,234.6,193.8,234.6z"/></g></svg>') no-repeat center center;
			transition: background-color .3s ease-in-out;
		}

		img,
		svg {
			@include fill;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__link {
		&.-cover-link {
			&::after {
				@include fill;
				z-index: 20;
				content: "";
			}
		}

		&:hover,
		&:focus {
			color: inherit;
		}
	}

	&__date {
		display: inline-flex;
		align-items: center;
		padding: 0.4rem 0.8rem;
		margin: 0 1.6rem -5px;
		font-size: rem(14px);
		font-weight: 800;
		color: $color-brand;
		background-color: $color-tercialy;
		border-radius: 5px;
		transform: translateY(-55%);
	}

	&__dates{
		@include flex(df, fww);
		align-items: center;
		&::before {
			position: relative;
			top: -1px;
			content: "";
			background: transparent url(/images/local/icons/v-202503271106/board-date.png) no-repeat 0 0;
			display: inline-block;
			width: 19px;
			height: 21px;
			margin-right: 1rem;
			overflow: hidden;
			transition: background-position .3s ease-in-out;
		}
		.card__date{
			margin: 0;
			transform: translateY(0);
			padding: 0;
			background-color: transparent;
			color: $color-secondary;
			&:not(:last-child){
				&::after{
					content: " - ";
					margin: 0 0.5rem;
				}
			}
			&::before{
				content: normal!important;
			}
		}
	}


	.info-hidden,
	.info-concept {
		display: inline-flex;

		&::before {
			top: 1px;
		}
	}

	&__description {
		font-size: rem(14px);
		line-height: 1.4;
		margin: 1.2rem 1.6rem 1rem;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		.board &{
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__title {
		margin: 0 1.6rem .75em;
		font-size: rem(16px);
		font-weight: 800;
		color: inherit;
		line-height: 1.2;
	}
	&__folder{
		display: block;
		margin: 0.2rem 1.6rem 0;
		font-size: rem(14px);
		font-weight: 800;
		@include link(){
			color: $color-secondary;
		}
	}
	&__link-to-document{
		margin-top: 2rem;
		display: block;
		text-align: right;
		font-size: rem(12px);
		&::after{
			content: url(/images/local/icons/v-202503271106/arrow-right.png);
			@include flex(dif, aic, jcc);
			width: 44px;
			height: 44px;
			background-color: #cfd8e3;
			border-radius: 50%;
			margin-left: 1rem;
			transform: rotate(-45deg);
			transition: transform .3s ease-in-out;
		}
	}
}

.board .card{
	padding: 2.7rem 1.6rem;
	background-color: #eaedf1;
	&__title{
		margin: 1rem 0 .75em;
	}
	&:hover{
		background-color: $color-secondary;
		color: $color-light;
		.card{
			&__dates{
				&:before {
					background-position-x: 100%;
				}
			}
			&__date{
				color: $color-light;
			}
			&__link-to-document{
				&::after{
					background-color: $color-brand;
					transform: rotate(0);
				}
			}
		}
		@include link(){
			color: $color-light;
		}
	}
}

.chosen {
	.card{
		display: flex;
		gap: 10%;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 7% 9%;
		background-color: #eaedf1;
		@include min(600px){
			flex-wrap: nowrap;
		}
		&__title{
			margin: 0.25em 0 .25em;
		}
		&__date{
			@include flex(df, fww);
			color: $color-secondary;
			align-items: center;
			background-color: transparent;
			margin: 0;
			padding: 0;
			&::before {
				position: relative;
				top: -1px;
				content: "";
				background: transparent url(/images/local/icons/v-202503271106/board-date.png) no-repeat 0 0;
				display: inline-block;
				width: 19px;
				height: 21px;
				margin-right: 1rem;
				overflow: hidden;
				transition: background-position .3s ease-in-out;
			}
		}
		&__description{
			margin: 0;
		}
		&__link-to-document{
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-shrink: 0;
			gap: 1rem;
			&::after{
				margin-left: 0;
				background-color: $color-dark;
			}
		}
		&:hover{
			box-shadow: 0 0 0;
			.card{
				&__dates{
					&:before {
						background-position-x: 100%;
					}
				}
				&__link-to-document{
					&::after{
						background-color: $color-secondary;
						transform: rotate(0);
					}
				}
			}
			@include link(){
				color: $color-secondary;
			}
		}
	}
	.ads{
		.card{
			&__link-to-document{
				&::after{
					margin-left: 0;
					background-color: $color-secondary;
				}
			}
			&:hover{
				.card{
					&__link-to-document{
						&::after{
							background-color: $color-dark;
						}
					}
				}
			}
		}
	}
}

@include link(".card__link") {
	color: $color-dark;
	text-decoration: none;
	.card__title{
		text-decoration: underline;
	}
}

@include link-over(".card__link") {
	color: $color-secondary;
	.card__image:empty{
		background-color: #bdbdbd;
	}
	.card__title{
		text-decoration: none;
	}
	.card__date{
		background-color: $color-secondary;
		color: $color-light;
	}
}
