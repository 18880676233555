.section.-homepage-events {
	padding: 4rem 0;
	margin-top: 2rem;
	margin-bottom: 3rem;
	background: #edf1f7 url(/images/local/v-202503271106/homepage-events-bg.jpg) no-repeat center top / cover;
	.element-type-heading{
		color: #000c19;
		margin-bottom: 0.8em;
	}
}

.homepage-events {
	.list {
		margin: 0;
		@include grid(500px 2 2%, 1024px 4 2%);
	}
}

.homepage-event {
	position: relative;
	padding-top: 30px;
	overflow: hidden;
	transition: box-shadow .3s ease-in-out;

	&__image {
		position: relative;
		margin: 0;
		overflow: hidden;
		height: 0;
		padding-bottom: ratio(1.685, 2);
		//118.66667%

		&:empty {
			transition: background-color .4s ease-in-out;
			background: lightgray encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="125px" fill="#fff" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g><path d="M881.1,132.5H118.9C59,132.5,10,181.5,10,241.4v517.3c0,59.9,49,108.9,108.9,108.9h762.2c59.9,0,108.9-49,108.9-108.9V241.4C990,181.5,941,132.5,881.1,132.5z M949.2,747.3c0,54.9-24.5,79.4-79.4,79.4H130.3c-54.9,0-79.4-24.5-79.4-79.4V252.7c0-54.9,24.5-79.4,79.4-79.4h739.5c54.9,0,79.4,24.5,79.4,79.4V747.3z M316.3,418.3L418.3,500l265.4-224.6l204.2,183.8v306.3H112.1V581.7L316.3,418.3z M193.8,234.6c-45.1,0-81.7,36.6-81.7,81.7s36.6,81.7,81.7,81.7s81.7-36.6,81.7-81.7S238.9,234.6,193.8,234.6z"/></g></svg>') no-repeat center center;

			@include link-over(&) {
				background-color: gray;
			}
		}

		img {
			position: absolute;
			@include fill();
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform .4s;
		}
	}

	&__body {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
	}

	&__date {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		z-index: 20;
		font-family: $font-signika;
		@include flex(dif, fww);
		font-size: rem(24px);
		font-weight: 500;
		padding: 1.5rem 1rem;
		text-align: center;
		line-height: 1.2;
		min-width: 130px;
		background-color: $color-secondary;
		color: $color-light;
		border-radius: 5px;
		transition: background .3s ease-in-out, color .3s ease-in-out;

		&-fullday {
			@include flex(df, fww, aic, jcc, fdc);
			flex-grow: 1;
		}

		&-dot {
			display: none;
		}

		&-day,
		&-dot,
		&-month {
			width: 100%;
			font-size: rem(18px);
			font-weight: 700;
		}

		&-day {
			font-size: rem(40px);
			font-weight: 300;
		}

		&-year {
			display: none;
		}
	}

	&__link {
		margin-bottom: 0;
		&::after{
			@include fill();
			top: -999rem;
			content: "";
			z-index: 25;
		}

		@include link(&) {
			color: #fff;
		}
	}

	&__title {
		padding: 17% 1.6rem 13%;
		font-size: rem(16px);
		margin: 0 0 0;
		font-weight: 800;
		text-align: center;
		//background: linear-gradient(0deg, rgba(#0061cf, 1) 0%, rgba(#003fcf, .85) 100%);
		background: $color-secondary;
		mask-image: url(/images/local/v-202503271106/homepage-event-mask.png);
		mask-repeat: no-repeat;
		mask-size: 100%;
		transition: background .3s ease-in-out, color .3s ease-in-out;
	}

	&__description {
		margin: 0;
	}

	.event__status {
		padding: 0 6%;
	}

	&__types {
		@include flex(df, fww);
		gap: 0 1.5rem;
	}

	&__type {
		display: inline-block;

		&__link {
			text-transform: uppercase;
			font-size: rem(12px);
			font-weight: 700;

			@include link(&) {
				text-decoration: none;
			}

			@include link-over(&) {
				text-decoration: underline;
			}
		}
	}
	@include link-over(&){
		box-shadow: 0px 35px 25px -25px rgba(#000000, .25);
		.homepage-event__date,
		.homepage-event__title{
			background-color: $color-tercialy;
			color: $color-dark;
		}
	}
}
