
.home {
	position: relative;
	display: flex;
	flex-direction: column;
	background: transparent url(/images/local/v-202503271106/home-bg.png) no-repeat center top;
	padding-top: 40px;
	z-index: 2;
	@include min(600px){
		margin-top: -47px;
	}
}

.home-bottom{
	position: relative;
	@include min(750px){
		background: transparent url(/images/local/v-202503271106/home-bottom-bg.jpg) no-repeat center top;
		&::after{
			position: absolute;
			content: "";
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			max-width: 1920px;
			width: 100%;
			padding-bottom: 116px;
			background: transparent url(/images/local/v-202503271106/home-bottom-prechod.png) no-repeat center top;
			z-index: 25;
		}
	}
}

.section {
	padding: 1rem 0;

	.element-type-heading {
		font-size: rem(34px);
		color: $color-brand;
		text-align: center;
		font-weight: 800;
		margin-bottom: 1em;
	}

	@include min(800px) {
		padding: 1.8rem 0;
	}
}

.ytb-videos{
	li{
		background-color: $color-light;
	}
	h2{
		font-size: rem(16px);
		font-weight: 800;
		margin: 1rem 1rem 0.25rem;
	}
	time{
		display: block;
		padding: 0rem 1rem 1rem;
		margin: 0;
		font-size: rem(14px);
		font-weight: 800;
		color: $color-secondary;
	}
	.wewr-ytb-video {
		&__wrapper {
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
			max-width: 100%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.section.-board{
	position: relative;
	&::before{
		content: "";
		position: absolute;
		bottom: 0;
		left: 4%;
		background: transparent url(/images/local/v-202503271106/board-bg.png) no-repeat 3% 70%;
		width: 401px;
		height: 275px;
		z-index: -1;
	}
}

.section.-chosen{
	padding-top: 0.3rem;
}
.chosen{
	.ads{
		.element-type-heading{
			color: $color-secondary;
		}
	}
	.dev-element-block{
		@include grid(700px 2 2%, 1024px 2 9.5%);
	}
	.list{
		&__item{
			margin-bottom: 0;
			&:nth-of-type(even) .card{
				background-color: $color-light;
			}
		}
	}
	&__more{
		text-align: left;
		&-button{
			text-align: center;
			//background-color: $color-light;
		}
	}
}
