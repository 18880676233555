.homepage-tiles{
	ul{
		@include grid(600px 4 2%, 1024px 8 4%);
	}
	//kvůli administraci
	.vismo-left-buttons-together li[data-group-element-id]{
		.vismo-checkbox,
		.vismo-context-button{
			left: 0;
		}
	}
}

.homepage-tile{
	&__link{
		display: flex;
		flex-direction: column;
	}
	&__image{
		margin: 0 0 0;
		svg,
		img{
			max-width: 90px;
			fill: $color-secondary;
		}
	}
	&__title{

	}
	&__description{
		margin: 0;
	}
	@include link-over(".homepage-tile__link"){
		svg{
			fill: $color-brand;
		}
	}
}
