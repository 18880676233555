@charset "UTF-8";
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	TABS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	CALENDAR -PAGE
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}

@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: .72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}

/*
* COMMON BLOCKS
*/
.section.-homepage-events {
  padding: 4rem 0;
  margin-top: 2rem;
  margin-bottom: 3rem;
  background: #edf1f7 url(/images/local/v-202503271106/homepage-events-bg.jpg) no-repeat center top/cover;
}

.section.-homepage-events .element-type-heading {
  color: #000c19;
  margin-bottom: 0.8em;
}

.homepage-events .list {
  margin: 0;
  display: grid;
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .homepage-events .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2%;
    gap: 2%;
  }
}

@media screen and (min-width: 1023px) {
  .homepage-events .list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2%;
    gap: 2%;
  }
}

.homepage-event {
  position: relative;
  padding-top: 30px;
  overflow: hidden;
  transition: box-shadow .3s ease-in-out;
}

.homepage-event__image {
  position: relative;
  margin: 0;
  overflow: hidden;
  height: 0;
  padding-bottom: 118.69436%;
}

.homepage-event__image:empty {
  transition: background-color .4s ease-in-out;
  background: lightgray url("data:image/svg+xml;utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='125px' fill='%23fff' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M881.1,132.5H118.9C59,132.5,10,181.5,10,241.4v517.3c0,59.9,49,108.9,108.9,108.9h762.2c59.9,0,108.9-49,108.9-108.9V241.4C990,181.5,941,132.5,881.1,132.5z M949.2,747.3c0,54.9-24.5,79.4-79.4,79.4H130.3c-54.9,0-79.4-24.5-79.4-79.4V252.7c0-54.9,24.5-79.4,79.4-79.4h739.5c54.9,0,79.4,24.5,79.4,79.4V747.3z M316.3,418.3L418.3,500l265.4-224.6l204.2,183.8v306.3H112.1V581.7L316.3,418.3z M193.8,234.6c-45.1,0-81.7,36.6-81.7,81.7s36.6,81.7,81.7,81.7s81.7-36.6,81.7-81.7S238.9,234.6,193.8,234.6z'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
}

.homepage-event__image:empty:hover, .homepage-event__image:empty:focus, .homepage-event__image:empty:active {
  background-color: gray;
}

.homepage-event__image img {
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform .4s;
}

.homepage-event__body {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.homepage-event__date {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 20;
  font-family: "Signika Negative", sans-serif;
  display: inline-flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1.5rem 1rem;
  text-align: center;
  line-height: 1.2;
  min-width: 130px;
  background-color: #003fcf;
  color: #ffffff;
  border-radius: 5px;
  transition: background .3s ease-in-out, color .3s ease-in-out;
}

.homepage-event__date-fullday {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.homepage-event__date-dot {
  display: none;
}

.homepage-event__date-day, .homepage-event__date-dot, .homepage-event__date-month {
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
}

.homepage-event__date-day {
  font-size: 2.5rem;
  font-weight: 300;
}

.homepage-event__date-year {
  display: none;
}

.homepage-event__link {
  margin-bottom: 0;
}

.homepage-event__link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top: -999rem;
  content: "";
  z-index: 25;
}

.homepage-event__link, .homepage-event__link:link, .homepage-event__link:visited {
  color: #fff;
}

.homepage-event__title {
  padding: 17% 1.6rem 13%;
  font-size: 1rem;
  margin: 0 0 0;
  font-weight: 800;
  text-align: center;
  background: #003fcf;
  mask-image: url(/images/local/v-202503271106/homepage-event-mask.png);
  mask-repeat: no-repeat;
  mask-size: 100%;
  transition: background .3s ease-in-out, color .3s ease-in-out;
}

.homepage-event__description {
  margin: 0;
}

.homepage-event .event__status {
  padding: 0 6%;
}

.homepage-event__types {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1.5rem;
}

.homepage-event__type {
  display: inline-block;
}

.homepage-event__type__link {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
}

.homepage-event__type__link, .homepage-event__type__link:link, .homepage-event__type__link:visited {
  text-decoration: none;
}

.homepage-event__type__link:hover, .homepage-event__type__link:focus, .homepage-event__type__link:active {
  text-decoration: underline;
}

.homepage-event:hover, .homepage-event:focus, .homepage-event:active {
  box-shadow: 0px 35px 25px -25px rgba(0, 0, 0, 0.25);
}

.homepage-event:hover .homepage-event__date,
.homepage-event:hover .homepage-event__title, .homepage-event:focus .homepage-event__date,
.homepage-event:focus .homepage-event__title, .homepage-event:active .homepage-event__date,
.homepage-event:active .homepage-event__title {
  background-color: #fbd834;
  color: #292A2D;
}

/*
* DESIGN COMPONENTS
*/
.section.-introduction {
  padding-top: 0;
  padding-bottom: 0;
}

.introduction {
  padding: 4rem 0;
}

.introduction .text-component {
  margin-bottom: 0;
}

.card {
  background-color: #ffffff;
  padding: 0 0 1.7rem;
  position: relative;
  transition: background-color .2s ease;
  height: 100%;
  box-shadow: 0 0 0 -25px;
  transition: box-shadow .3s ease-in-out;
}

.card:hover {
  background-color: #ffffff;
  box-shadow: 0px 35px 25px -25px rgba(0, 0, 0, 0.25);
}

.card:focus-within {
  background-color: #ffffff;
}

.card__image {
  position: relative;
  margin: 0 0 0;
  max-width: 100%;
  height: 0;
  padding-bottom: 66.66667%;
  width: 100%;
}

.card__image:empty {
  background: lightgray url("data:image/svg+xml;utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='85px' fill='%23fff' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M881.1,132.5H118.9C59,132.5,10,181.5,10,241.4v517.3c0,59.9,49,108.9,108.9,108.9h762.2c59.9,0,108.9-49,108.9-108.9V241.4C990,181.5,941,132.5,881.1,132.5z M949.2,747.3c0,54.9-24.5,79.4-79.4,79.4H130.3c-54.9,0-79.4-24.5-79.4-79.4V252.7c0-54.9,24.5-79.4,79.4-79.4h739.5c54.9,0,79.4,24.5,79.4,79.4V747.3z M316.3,418.3L418.3,500l265.4-224.6l204.2,183.8v306.3H112.1V581.7L316.3,418.3z M193.8,234.6c-45.1,0-81.7,36.6-81.7,81.7s36.6,81.7,81.7,81.7s81.7-36.6,81.7-81.7S238.9,234.6,193.8,234.6z'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
  transition: background-color .3s ease-in-out;
}

.card__image img,
.card__image svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.card__link.-cover-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  content: "";
}

.card__link:hover, .card__link:focus {
  color: inherit;
}

.card__date {
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 0.8rem;
  margin: 0 1.6rem -5px;
  font-size: 0.875rem;
  font-weight: 800;
  color: #162847;
  background-color: #fbd834;
  border-radius: 5px;
  transform: translateY(-55%);
}

.card__dates {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.card__dates::before {
  position: relative;
  top: -1px;
  content: "";
  background: transparent url(/images/local/icons/v-202503271106/board-date.png) no-repeat 0 0;
  display: inline-block;
  width: 19px;
  height: 21px;
  margin-right: 1rem;
  overflow: hidden;
  transition: background-position .3s ease-in-out;
}

.card__dates .card__date {
  margin: 0;
  transform: translateY(0);
  padding: 0;
  background-color: transparent;
  color: #003fcf;
}

.card__dates .card__date:not(:last-child)::after {
  content: " - ";
  margin: 0 0.5rem;
}

.card__dates .card__date::before {
  content: normal !important;
}

.card .info-hidden,
.card .info-concept {
  display: inline-flex;
}

.card .info-hidden::before,
.card .info-concept::before {
  top: 1px;
}

.card__description {
  font-size: 0.875rem;
  line-height: 1.4;
  margin: 1.2rem 1.6rem 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.board .card__description {
  margin-left: 0;
  margin-right: 0;
}

.card__title {
  margin: 0 1.6rem .75em;
  font-size: 1rem;
  font-weight: 800;
  color: inherit;
  line-height: 1.2;
}

.card__folder {
  display: block;
  margin: 0.2rem 1.6rem 0;
  font-size: 0.875rem;
  font-weight: 800;
}

.card__folder a,
.card__folder a:link,
.card__folder a:visited {
  color: #003fcf;
}

.card__link-to-document {
  margin-top: 2rem;
  display: block;
  text-align: right;
  font-size: 0.75rem;
}

.card__link-to-document::after {
  content: url(/images/local/icons/v-202503271106/arrow-right.png);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: #cfd8e3;
  border-radius: 50%;
  margin-left: 1rem;
  transform: rotate(-45deg);
  transition: transform .3s ease-in-out;
}

.board .card {
  padding: 2.7rem 1.6rem;
  background-color: #eaedf1;
}

.board .card__title {
  margin: 1rem 0 .75em;
}

.board .card:hover {
  background-color: #003fcf;
  color: #ffffff;
}

.board .card:hover .card__dates:before {
  background-position-x: 100%;
}

.board .card:hover .card__date {
  color: #ffffff;
}

.board .card:hover .card__link-to-document::after {
  background-color: #162847;
  transform: rotate(0);
}

.board .card:hover a,
.board .card:hover a:link,
.board .card:hover a:visited {
  color: #ffffff;
}

.chosen .card {
  display: flex;
  gap: 10%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 7% 9%;
  background-color: #eaedf1;
}

@media screen and (min-width: 600px) {
  .chosen .card {
    flex-wrap: nowrap;
  }
}

.chosen .card__title {
  margin: 0.25em 0 .25em;
}

.chosen .card__date {
  display: flex;
  flex-wrap: wrap;
  color: #003fcf;
  align-items: center;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.chosen .card__date::before {
  position: relative;
  top: -1px;
  content: "";
  background: transparent url(/images/local/icons/v-202503271106/board-date.png) no-repeat 0 0;
  display: inline-block;
  width: 19px;
  height: 21px;
  margin-right: 1rem;
  overflow: hidden;
  transition: background-position .3s ease-in-out;
}

.chosen .card__description {
  margin: 0;
}

.chosen .card__link-to-document {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  gap: 1rem;
}

.chosen .card__link-to-document::after {
  margin-left: 0;
  background-color: #292A2D;
}

.chosen .card:hover {
  box-shadow: 0 0 0;
}

.chosen .card:hover .card__dates:before {
  background-position-x: 100%;
}

.chosen .card:hover .card__link-to-document::after {
  background-color: #003fcf;
  transform: rotate(0);
}

.chosen .card:hover a,
.chosen .card:hover a:link,
.chosen .card:hover a:visited {
  color: #003fcf;
}

.chosen .ads .card__link-to-document::after {
  margin-left: 0;
  background-color: #003fcf;
}

.chosen .ads .card:hover .card__link-to-document::after {
  background-color: #292A2D;
}

.card__link,
.card__link:link,
.card__link:visited {
  color: #292A2D;
  text-decoration: none;
}

.card__link .card__title,
.card__link:link .card__title,
.card__link:visited .card__title {
  text-decoration: underline;
}

.card__link:hover,
.card__link:focus,
.card__link:active {
  color: #003fcf;
}

.card__link:hover .card__image:empty,
.card__link:focus .card__image:empty,
.card__link:active .card__image:empty {
  background-color: #bdbdbd;
}

.card__link:hover .card__title,
.card__link:focus .card__title,
.card__link:active .card__title {
  text-decoration: none;
}

.card__link:hover .card__date,
.card__link:focus .card__date,
.card__link:active .card__date {
  background-color: #003fcf;
  color: #ffffff;
}

.board-card {
  background-color: #ffffff;
  padding: 5%;
  position: relative;
  box-shadow: 0 0 0 2px #ededed;
  min-height: 16em;
}

@media screen and (min-width: 650px) {
  .board-card {
    padding: 2em;
  }
}

@media screen and (min-width: 1024px) {
  .board-card {
    padding: 3em 3vw;
  }
}

.board-card__date {
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 1.375rem;
  font-weight: bold;
  color: #292A2D;
  margin: 0 0 0;
}

.board-card__date.-to {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 1.25em;
}

.board-card__title {
  margin-top: 0;
  margin-bottom: .33em;
  font-size: 1.375rem;
  color: inherit;
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  line-height: 1.36364;
}

.board-card__link,
.board-card__link:link,
.board-card__link:visited {
  color: #162847;
  text-decoration: underline;
}

.board-card__link:hover,
.board-card__link:focus,
.board-card__link:active {
  color: #003fcf;
  text-decoration: none;
}

.abo__form .label {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.abo__form .input {
  background-color: #ffffff;
  border-color: #292A2D;
  border-width: 0;
  color: #292A2D;
  min-height: 55px;
  box-shadow: 0 0 0;
  max-width: 380px;
}

.abo__form .gdpr-agreement {
  margin: 0;
}

.abo__form .button.-primary {
  background-color: transparent;
  padding: 0;
  min-height: 0;
  font-weight: 700;
}

.abo__form .button.-primary:hover, .abo__form .button.-primary:focus, .abo__form .button.-primary:active {
  background-color: transparent;
  text-decoration: underline;
}

.homepage-tiles ul {
  display: grid;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .homepage-tiles ul {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2%;
    gap: 2%;
  }
}

@media screen and (min-width: 1023px) {
  .homepage-tiles ul {
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 4%;
    gap: 4%;
  }
}

.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-checkbox,
.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-context-button {
  left: 0;
}

.homepage-tile__link {
  display: flex;
  flex-direction: column;
}

.homepage-tile__image {
  margin: 0 0 0;
}

.homepage-tile__image svg,
.homepage-tile__image img {
  max-width: 90px;
  fill: #003fcf;
}

.homepage-tile__description {
  margin: 0;
}

.homepage-tile .homepage-tile__link:hover svg,
.homepage-tile .homepage-tile__link:focus svg,
.homepage-tile .homepage-tile__link:active svg {
  fill: #162847;
}

.blender {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-position: 50% 50%;
  background-size: cover;
}

.blender__container {
  position: relative;
  background: #292A2D url(/images/local/blender/v-202503271106/0.jpg) no-repeat 50% 50%/cover;
  display: none;
}

@media screen and (min-width: 600px) {
  .blender__container {
    display: block;
    padding-bottom: 315px;
  }
}

@media screen and (min-width: 1024px) {
  .blender__container {
    padding-bottom: 31.25%;
  }
}

@media screen and (min-width: 1920px) {
  .blender__container {
    padding-bottom: 590px;
  }
}

.blender__buttons {
  position: absolute;
  z-index: 15;
  right: 3%;
  bottom: 2rem;
  display: flex;
}

.blender__buttons[data-blender-buttons] {
  right: 50%;
  transform: translateX(50%);
}

.blender__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0;
  background: transparent none;
  box-shadow: none;
  width: 50px;
  height: 50px;
  background-color: #bfbfbf;
  cursor: pointer;
}

.blender__button:first-of-type {
  background-color: #dddddd;
}

.blender__button svg {
  fill: #292A2D;
}

.blender__button:hover, .blender__button:focus, .blender__button:active {
  background-color: #003fcf;
  color: #ffffff;
}

.blender__button:hover svg, .blender__button:focus svg, .blender__button:active svg {
  fill: #ffffff;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item.-animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}

.blender__title, .blender__description {
  position: absolute;
  right: 0em;
  bottom: 4%;
  z-index: 51;
}

.blender__description {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35.8em;
  padding: 1rem 1em 1rem 6%;
  max-width: 55%;
  height: 140px;
}

.blender__description a,
.blender__description a:link,
.blender__description a:visited {
  color: #ffffff;
  font-size: 1.3125rem;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  margin: 0 0 .25em;
  text-decoration: underline;
}

.blender__description a:hover,
.blender__description a:focus,
.blender__description a:active {
  text-decoration: none;
}

.blender__description strong {
  font-size: 1.3125rem;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  margin: 0 0 .25em;
}

.blender__description span {
  font-size: 1rem;
}

.blender__description:before {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0;
  right: -40px;
  background-color: rgba(0, 0, 0, 0.75);
  transform: skew(-14.5deg);
  z-index: -1;
}

.figure__caption {
  padding: .725em 10px 1.5em;
  border-bottom: 1px #cccccc solid;
}

.figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
  padding-left: 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 10px .72em;
  background-size: 20px auto;
}

@media screen and (min-width: 800px) {
  .figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
    padding-left: 50px;
    background-position: 10px .48em;
    background-size: auto;
  }
}

.figure__caption.-photo {
  background-image: icon("camera", #ccc);
}

.figure__caption.-video {
  background-image: icon("movie", #ccc);
}

.figure__caption.-illustration {
  background-image: icon("picture", #ccc);
}

.tabs-sections [role="tabsection"] {
  padding: 0;
}

.tabs {
  display: none;
}

.tabs [role="tab"] {
  font-size: 2.125rem;
  line-height: 1.1;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

.tabs[data-tabs] > p {
  display: none;
}

.tablist[role="tablist"] {
  list-style-type: none;
  margin: 0 0 2.5rem 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.tablist[role="tablist"] .tablist__item {
  padding: 0 1rem;
  margin: 0;
  display: block;
  background-color: transparent;
  color: #878787;
  cursor: pointer;
}

.tablist[role="tablist"] .tablist__item:hover, .tablist[role="tablist"] .tablist__item:focus, .tablist[role="tablist"] .tablist__item:active {
  text-decoration: underline;
}

.tablist[role="tablist"] .tablist__item:hover, .tablist[role="tablist"] .tablist__item:focus {
  outline: 0;
}

.tablist[role="tablist"] .tablist__item[aria-selected="true"] {
  cursor: default;
  font-size: 2.75rem;
  background-color: transparent;
  color: #003fcf;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (min-width: 650px) {
  .tabs {
    display: block;
  }
}

@media screen and (min-width: 650px) and (min-width: 651px) {
  .tabs-sections [role="tabsection"] h2.element-type-heading,
  .tabs-sections [role="tabsection"] h3.element-type-heading {
    position: absolute;
    left: -9999em;
    top: 0;
    width: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
    outline: 0;
  }
}

@media screen and (min-width: 650px) {
  .tabs-sections [role="tabsection"][aria-hidden="true"] {
    display: none;
  }
}

.section.-banners {
  padding: 0;
}

.section.-banners .text-component {
  padding: 4rem 0;
  margin-bottom: 0;
}

/*
* HIGHEST PRIORITY
*/
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  background: transparent url(/images/local/v-202503271106/home-bg.png) no-repeat center top;
  padding-top: 40px;
  z-index: 2;
}

@media screen and (min-width: 600px) {
  .home {
    margin-top: -47px;
  }
}

.home-bottom {
  position: relative;
}

@media screen and (min-width: 750px) {
  .home-bottom {
    background: transparent url(/images/local/v-202503271106/home-bottom-bg.jpg) no-repeat center top;
  }
  .home-bottom::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1920px;
    width: 100%;
    padding-bottom: 116px;
    background: transparent url(/images/local/v-202503271106/home-bottom-prechod.png) no-repeat center top;
    z-index: 25;
  }
}

.section {
  padding: 1rem 0;
}

.section .element-type-heading {
  font-size: 2.125rem;
  color: #162847;
  text-align: center;
  font-weight: 800;
  margin-bottom: 1em;
}

@media screen and (min-width: 800px) {
  .section {
    padding: 1.8rem 0;
  }
}

.ytb-videos li {
  background-color: #ffffff;
}

.ytb-videos h2 {
  font-size: 1rem;
  font-weight: 800;
  margin: 1rem 1rem 0.25rem;
}

.ytb-videos time {
  display: block;
  padding: 0rem 1rem 1rem;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 800;
  color: #003fcf;
}

.ytb-videos .wewr-ytb-video__wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  max-width: 100%;
}

.ytb-videos .wewr-ytb-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section.-board {
  position: relative;
}

.section.-board::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 4%;
  background: transparent url(/images/local/v-202503271106/board-bg.png) no-repeat 3% 70%;
  width: 401px;
  height: 275px;
  z-index: -1;
}

.section.-chosen {
  padding-top: 0.3rem;
}

.chosen .ads .element-type-heading {
  color: #003fcf;
}

.chosen .dev-element-block {
  display: grid;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .chosen .dev-element-block {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2%;
    gap: 2%;
  }
}

@media screen and (min-width: 1023px) {
  .chosen .dev-element-block {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 9.5%;
    gap: 9.5%;
  }
}

.chosen .list__item {
  margin-bottom: 0;
}

.chosen .list__item:nth-of-type(even) .card {
  background-color: #ffffff;
}

.chosen__more {
  text-align: left;
}

.chosen__more-button {
  text-align: center;
}
